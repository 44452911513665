import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import EditorData from 'components/editor-data/EditorData';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import { getDateFormatted } from 'components/ui-components/DateFormat';
import Status from '../helpers/status';

import '../styles/publish-detail-logs-logview-item.scss';
import { searchDatasetManagerPath } from '../helpers/search-datasetmanager';
import { LogSeverity } from '../types/publishLogs.type';

const getSeverity = (level) => {
    const severity = isNaN(level) ? level : parseInt(level);

    if (severity === 2 || severity === LogSeverity.NOTICE) {
        return <span className="publish-detail-logs-logview-item--notice">notice</span>;
    } else if (severity === 3 || severity === LogSeverity.INFO) {
        return <span className="publish-detail-logs-logview-item--info">info</span>;
    } else if (severity === LogSeverity.DEBUG) {
        return <span className="publish-detail-logs-logview-item--info">debug</span>;
    } else if (severity === 5 || severity === LogSeverity.ERROR) {
        return <span className="publish-detail-logs-logview-item--error">error</span>;
    } else if (severity === 6 || severity === LogSeverity.CRITICAL) {
        return <span className="publish-detail-logs-logview-item--critical">critical</span>;
    } else if (severity === 7 || severity === LogSeverity.WARNING) {
        return <span className="publish-detail-logs-logview-item--warning">warning</span>;
    }

    return <span>unknown: {level}</span>;
};

const PublishDetailLogsLogviewItem = ({ item, onShowTaskId, taskIdClickable }) => {
    const [openData, setOpenData] = useState(false);

    const campaignId = EditorData.getId();

    const foundDatasetManagerPath = searchDatasetManagerPath();

    const showTaskId = (e, taskId) => {
        e.stopPropagation();
        e.preventDefault();
        onShowTaskId(taskId);
    };

    const getData = () => {
        const { data } = item;
        if (!data) return;
        else if (typeof data === 'string') return JSON.stringify(JSON.parse(item.data), null, 2);
        else if (typeof data === 'object' && Object.keys(data).length) return JSON.stringify(data, null, 2);
    };

    const data = getData();

    const handleOpenData = () => {
        if (data) setOpenData(!openData);
    };
    return (
        <div className="publish-detail-logs-logview-item">
            <div
                onClick={() => handleOpenData()}
                className={classNames('publish-detail-logs-logview-item__header', {
                    'publish-detail-logs-logview-item__header--open': openData,
                    'publish-detail-logs-logview-item__header--clickable': data
                })}>
                <div className="publish-detail-logs-logview-item__header__toggle">
                    {data && data.length && <Icon>{openData ? 'expand_less' : 'expand_more'}</Icon>}
                </div>
                <div>{getDateFormatted(parseInt(item.timestamp) * 1000, 'dateTimeExact')}</div>
                <div>{getSeverity(item.severity)}</div>
                <div>
                    {taskIdClickable ? (
                        <Button variant="outlined" size="small" onClick={(e) => showTaskId(e, item.taskId)}>
                            {item.taskId || -1}
                        </Button>
                    ) : (
                        <React.Fragment>{item.taskId}</React.Fragment>
                    )}
                </div>
                <div>
                    {foundDatasetManagerPath ? (
                        <Link
                            to={{
                                pathname: `/editor/${campaignId}/${foundDatasetManagerPath}/general`,
                                state: { itemIds: item.itemId }
                            }}>
                            {item.itemId}
                        </Link>
                    ) : (
                        item.itemId
                    )}
                </div>
                <div>{item.location || item.application}</div>
                <div>{item.message}</div>
            </div>
            {openData && (
                <div className="publish-detail-logs-logview-item__content">
                    <Tooltip title="Copy JSON to clipboard">
                        <IconButton size="small" className="publish-detail-logs-logview-item__content__btn" onClick={() => Status.copyToClipboard(data)}>
                            <Icon fontSize="inherit">content_copy</Icon>
                        </IconButton>
                    </Tooltip>
                    <pre>{data}</pre>
                </div>
            )}
        </div>
    );
};

export default PublishDetailLogsLogviewItem;

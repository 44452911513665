// *V3*
/**
 * Log severity enum.
 * DEFAULT = "DEFAULT",
 * DEBUG = "DEBUG",
 * INFO = "INFO",
 * NOTICE = "NOTICE",
 * WARNING = "WARNING",
 * ERROR = "ERROR",
 * CRITICAL = "CRITICAL",
 * ALERT = "ALERT",
 * EMERGENCY = "EMERGENCY"
 */
export enum LogSeverity {
    DEFAULT = 'DEFAULT',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    NOTICE = 'NOTICE',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    CRITICAL = 'CRITICAL',
    ALERT = 'ALERT',
    EMERGENCY = 'EMERGENCY'
}

export type LogDataFields = { [key: string]: LogDataFields } | string | Array<LogDataFields> | number | null | boolean;

/**
 * A log entry
 */
export interface LogEntry {
    /**
     * The severity of the log
     */
    severity: LogSeverity;
    /**
     * The severity of the log
     */
    message: string;
    /**
     * The severity of the log
     */
    data: LogDataFields;
    /**
     * The severity of the log
     */
    taskId: number;
    /**
     * The severity of the log
     */
    application: string;
}

/**
 * The reponse for the logs endpoint
 */
export interface LogsResponse {
    /**
     * The severity of the log
     */
    entries: Array<LogEntry>;
    /**
     * The severity of the log
     */
    pageToken: string;
}

/**
 * The log type
 */
export type LogType = 'logs' | 'tree';

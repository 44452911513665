import React from 'react';
import classNames from 'classnames';
import Status from 'components/ui-components/Status';
import Loader from 'components/ui-components/Loader';
import Dialog from 'components/ui-components/Dialog';
import Translation from 'components/data/Translation';
import PublishDetailLogsLogview from './publish-detail-logs-logview';
import PublishDetailLogsTreeview from './publish-detail-logs-treeview';

const PublishDetailLogsDialog = ({ dataTree, dataLogs, loadingTree, loadingLogs, errorTree, errorLogs, view, jobId, setShowDialog, setView, version }) => {
    return (
        <Dialog
            title={
                <div className="publish-detail-logs__title">
                    <Status status={status} className="publish-detail-logs__status" /> {Translation.get('publish.detailLogsDialog.logsForJob', 'editor')}:{' '}
                    {jobId}
                </div>
            }
            onClose={() => setShowDialog(false)}
            open={true}
            fullScreen>
            <div className="publish-detail-logs__tabs">
                <div
                    className={classNames('publish-detail-logs__tabs__tab', {
                        'publish-detail-logs__tabs__tab--active': view === 'logs'
                    })}
                    onClick={() => setView('logs')}>
                    {Translation.get('publish.detailLogsDialog.logView.copy', 'editor')}
                </div>
                {version < 3 && (
                    <div
                        className={classNames('publish-detail-logs__tabs__tab', {
                            'publish-detail-logs__tabs__tab--active': view === 'tree'
                        })}
                        onClick={() => setView('tree')}>
                        {Translation.get('publish.detailLogsDialog.taskView.copy', 'editor')}
                    </div>
                )}
            </div>
            <div className="publish-detail-logs__content">
                {view === 'logs' && (
                    <Loader isLoading={loadingLogs}>
                        {errorLogs ? (
                            <div className="publish-detail-logs__error">{errorLogs}</div>
                        ) : (
                            <PublishDetailLogsLogview data={dataLogs} taskIdClickable />
                        )}
                    </Loader>
                )}
                {view === 'tree' && (
                    <Loader isLoading={loadingTree}>
                        {errorTree ? (
                            <div className="publish-detail-logs__error">{errorTree}</div>
                        ) : (
                            <PublishDetailLogsTreeview data={dataTree} jobId={jobId} />
                        )}
                    </Loader>
                )}
            </div>
        </Dialog>
    );
};

export default PublishDetailLogsDialog;

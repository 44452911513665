import Request from 'components/data/Request';
import EditorData from 'components/editor-data/EditorData';
import PublishV2 from './publish-v2';
import PublishV3 from './publish-v3';
import { LogType } from '../types/publishLogs.type';

interface Response {
    success: boolean;
    data?: any;
    message?: string;
    error?: any;
}

/**
 * PublishHelper
 * This helper handles the API requests for the publishing.
 */
class PublishEngineAdapter {
    static async publish(publishProfile) {
        if (publishProfile.version === 3) {
            // PE V3
            return await PublishV3.publish(publishProfile);
        } else {
            // PE V2
            return await PublishV2.publish(publishProfile);
        }
    }

    /**
     * Poll a publish job on the PublishEngine V2
     * @param {string} jobId
     * @param {number | undefined} version
     */
    static async poll(jobId: string, version: number) {
        if (version === 3) {
            // PE V3
            return await PublishV3.poll(jobId);
        } else {
            // PE V2
            return await PublishV2.poll(jobId);
        }
    }

    static async cancel(jobId: string, version: number) {
        if (version === 3) {
            // PE V3
            return await PublishV3.cancel(jobId);
        } else {
            // PE V2
            return await PublishV2.cancel(jobId);
        }
    }

    static async history() {
        try {
            const response: Response = await Request.post('publishing/historyList', {
                campaignId: EditorData.getId()
            });
            if (response.success) {
                if (response.data) {
                    const history = response.data;
                    const adaptedHistory = history.map((entry) => {
                        const newEntry = { ...entry };
                        const jobId = newEntry.jobToken ? newEntry.jobToken : newEntry.jobId;
                        delete newEntry.jobToken;
                        return {
                            ...newEntry,
                            jobId
                        };
                    });
                    return adaptedHistory;
                }
                return [];
            } else {
                console.error(response.error, response.message);
                return false;
            }
        } catch (e) {
            console.error(e);
        }
    }

    // Gets logs
    static async logs(version: number, jobId: string, type: LogType, customParam) {
        if (version === 3) {
            // PE V3
            const data = await PublishV3.logs(jobId, type, customParam);

            return data?.entries;
        } else {
            // PE V2
            return await PublishV2.logs(jobId, type);
        }
    }
}

export default PublishEngineAdapter;

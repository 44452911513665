import React, { useState, useEffect, useRef } from 'react';
import User from 'components/data/User';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import PublishDetailLogsDialog from './publish-detail-logs-dialog';
import PublishEngineAdapter from '../helpers/engineAdapter';
import '../styles/publish-detail-logs.scss';

const PublishDetailLogs = ({ jobId, status, className, customParam, version = 2 }) => {
    const startView = 'logs';
    const [showDialog, setShowDialog] = useState(false);
    const [view, setView] = useState(startView);
    const [dataTree, setDataTree] = useState(null);
    const [dataLogs, setDataLogs] = useState(null);
    const [loadingTree, setLoadingTree] = useState(true);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [errorTree, setErrorTree] = useState(null);
    const [errorLogs, setErrorLogs] = useState(null);
    const timerLogs = useRef(null);
    const timerTree = useRef(null);
    const interval = 10000;

    const clearTimers = () => {
        if (timerLogs.current) {
            clearTimeout(timerLogs.current);
            timerLogs.current = null;
        }
        if (timerTree.current) {
            clearTimeout(timerTree.current);
            timerTree.current = null;
        }
    };

    useEffect(() => {
        if (showDialog) {
            if (view === 'logs' || view === 'tree' || status === 'processing') {
                const getData = async (currentView) => {
                    try {
                        const logs = await PublishEngineAdapter.logs(version, jobId, currentView, customParam);
                        if (currentView === 'logs') {
                            setDataLogs(logs);
                            setErrorLogs(null);
                            setLoadingLogs(false);
                        } else if (currentView === 'tree') {
                            setDataTree(logs);
                            setErrorTree(null);
                            setLoadingTree(false);
                        }
                        clearTimers();

                        if (status === 'processing' || status === 'starting') {
                            if (currentView === 'logs') {
                                timerLogs.current = setTimeout(() => getData('logs'), interval);
                            }
                            if (currentView === 'tree') {
                                timerTree.current = setTimeout(() => getData('tree'), interval);
                            }
                        }
                    } catch (e) {
                        const { error, message } = e;
                        console.error(error, message);

                        if (currentView === 'logs') {
                            setErrorLogs(message);
                            setLoadingLogs(false);
                        } else if (currentView === 'tree') {
                            setErrorTree(message);
                            setLoadingTree(false);
                        }
                    }
                };
                getData(view);
            }
        } else {
            clearTimers();
        }

        return () => clearTimers();
    }, [view, jobId, showDialog]);

    // Component is only accessible to super admins and users with the publishLogs right.
    const hasAccess = User.get('type') === 'superadmin' || User.hasRight('publishLogs');

    if (!hasAccess) return null;

    return (
        <div className="publish-detail-logs">
            <Button onClick={() => setShowDialog(true)} variant="outlined" className={className}>
                {Translation.get('publishTab.logs', 'bricks')}
            </Button>

            {showDialog && (
                <PublishDetailLogsDialog
                    dataLogs={dataLogs}
                    dataTree={dataTree}
                    errorLogs={errorLogs}
                    errorTree={errorTree}
                    jobId={jobId}
                    loadingLogs={loadingLogs}
                    loadingTree={loadingTree}
                    setShowDialog={setShowDialog}
                    setView={setView}
                    view={view}
                    version={version}
                />
            )}
        </div>
    );
};

export default PublishDetailLogs;

import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';
import * as Sentry from '@sentry/react';
import { get, set } from 'lodash';
import moment from 'moment';
import { clamp as _clamp } from 'lodash';
import Request from 'components/data/Request';
import PublishProfileParser from 'components/publishing/PublishProfileParser';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { getDateFormatted } from 'components/ui-components/DateFormat';

class PublishV2 {
    /**
     * Start a publish job on the PublishEngine V2
     * @param {object} publishProfile
     * @returns {object}
     */
    static async publish(publishProfile) {
        // Start the publishing process for a profile.
        const profileKey = publishProfile.key;

        // Start parsing the profile
        const parsedProfile = await PublishProfileParser.parse(publishProfile);

        // An error ocurred, throw the error
        if (!parsedProfile) {
            console.log("We couldn't start your publish action. Publish profile parsing failed");

            const jobId = `fake-${uuidv4()}`;
            const jobResults = {
                jobId,
                status: 'failed',
                errors: [{ description: "We couldn't start your publish action. Publish profile parsing failed" }]
            };

            return jobResults;
        }

        // Allow scheduled publishing
        let scheduledPublishTime = null;
        if (publishProfile.scheduledPublishTime) {
            const scheduledPublishTimeSet = EditorData.getValueFromModel('settings.planning.scheduledPublishTime');
            if (scheduledPublishTimeSet) {
                scheduledPublishTime = scheduledPublishTimeSet;
            }
        }

        const response = await PublishV2.start(parsedProfile, profileKey, scheduledPublishTime);

        // API has been reached and job has been started.
        if (response.jobId) {
            if (publishProfile.partialPublish && publishProfile.tasks && publishProfile.tasks[0]) {
                const task = publishProfile.tasks[0];

                // Set the lastPublished date for all the items that are published
                // The campaignId is used, because otherwise it will show the wrong data if you copy a campaign
                publishProfile.partialPublish.forEach((uuid) => {
                    EditorData.setModel(`${task.dataModel}.${uuid}.lastPublished.${EditorData.getId()}`, moment());
                });

                // Save the campaign with the new lastPublished dates
                const editor = EditorData.get();
                Request.post('editor/save', { id: editor.id, data: editor.data, versionNr: editor.versionNr }).then(() => {});
            }

            const jobResults = {
                status: 'processing',
                startedOn: getDateFormatted(Date.now(), 'dateTimeIso'), // Client time, gets overwritten after first status call with server time.
                jobId: response.jobId
            };

            return jobResults;
        } else {
            // Something went wrong, no jobToken present.
            const thisError = response.errorMessage;
            const fakeJobToken = `fake-${uuidv4()}`;
            console.error(`Starting publish action failed: ${thisError}`);

            const jobResults = {
                status: 'failed',
                errors: [{ description: thisError }],
                jobId: fakeJobToken
            };

            return jobResults;
        }
    }

    /**
     * Start publishing a task
     * @param {*} publishProfilesList The list of all the tasks
     * @param {*} publishProfile The publish profile name
     */
    static async start(publishProfilesList, publishProfile, scheduledPublishTime) {
        // Send to the server
        try {
            const response = await Request.post('publishing/start', {
                tasks: publishProfilesList,
                campaignId: EditorData.getId(),
                publishProfile: publishProfile,
                startDate: scheduledPublishTime
            });
            return {
                jobId: response.data.jobToken
            };
        } catch (e) {
            // In case of an error, display the error
            let thisError = e.message;
            if (typeof e.message === 'object' && e.message) {
                thisError = `${e.message.message}`;
            }
            const error = {
                jobId: `fake-${uuidv4()}`,
                status: 'connectionFail',
                error: e.error,
                errorMessage: thisError,
                logs: []
            };
            console.error('error', error);
            SnackbarUtils.error('Error starting the publish job. ');
        }
    }

    /**
     * Cancel a specific job
     * @param {string} jobId
     */
    static async cancel(jobId) {
        try {
            await Request.post(
                'publishing/cancel',
                {
                    jobToken: jobId
                },
                {}
            );
        } catch (error) {
            console.log(`Cancelling failed: ${error.message}`);
        }
    }

    /**
     * Poll the status of a publish task
     * @param {string} jobId
     */
    static async poll(jobId) {
        try {
            const response = await Request.post('publishing/status', {
                jobToken: jobId
            });

            const status = (() => {
                if (!response.success) return 'connectionFail';
                if (response.error) return 'failed';
                return PublishV2.getStatus(response.data?.status);
            })();

            const transformedPollResponse = PublishV2.transformPollResponse(response.data);
            return {
                ...transformedPollResponse,
                jobId,
                status
            };
        } catch (e) {
            let error;

            if (e.success === 0) {
                error = {
                    jobId,
                    status: 'failed',
                    error: e.error,
                    message: e.message,
                    data: {
                        errors: [`${e.error} - ${e.message}`]
                    }
                };
            } else {
                error = {
                    jobId,
                    status: 'connectionFail',
                    error: e.error,
                    message: e.message,
                    logs: []
                };
            }
            return error;
        }
    }

    /**
     * We receive a status string fom the api, we want to translate that to the status codes the frontend uses.
     * @param {string} apiStatus
     * @returns string
     */
    static getStatus = (apiStatus) => {
        switch (apiStatus) {
            case 'connectionFail':
            case 'cancelled':
            case 'failed':
            case 'scheduled':
                return apiStatus;
            case 'finished':
                return 'done';
            default:
                return 'processing';
        }
    };

    /**
     * Transform the V2 poll response structure to the structure the front end uses.
     * @param {object} response
     * @returns {object}
     */
    static transformPollResponse = (response) => {
        const transFormedResponse = {};
        Object.entries(response).forEach(([key, value]) => {
            if (key === 'tasksDone') {
                transFormedResponse.finishedTasks = value;
            } else if (key === 'tasksTotal') {
                transFormedResponse.totalTasks = value;
            } else if (key === 'products') {
                if (value?.length) {
                    const transformedProducts = [];
                    value.forEach((product) => {
                        transformedProducts.push({ name: product.name, value: product.product });
                    });
                    transFormedResponse.products = transformedProducts;
                } else {
                    transFormedResponse.products = [];
                }
            } else if (key === 'taskProgress') {
                transFormedResponse.percentage = value ? _clamp(parseInt(value), 1, 100) : 0;
            } else if (key === 'errors') {
                value.forEach((error) => {
                    const errorKey = slugify(error.message, { lower: true, strict: true });
                    if (get(transFormedResponse, `messages.errors.${errorKey}`)) {
                        const currentCount = get(transFormedResponse, `messages.errors.${errorKey}.count`);
                        set(transFormedResponse, `messages.errors.${errorKey}.count`, currentCount + 1);
                        const currentItemIds = get(transFormedResponse, `messages.errors.${errorKey}.itemIds`, []);
                        set(transFormedResponse, `messages.errors.${errorKey}.itemIds`, [...new Set([...currentItemIds, error.itemId])]);
                    } else {
                        set(transFormedResponse, `messages.errors.${errorKey}`, { count: 1, description: error.message, itemIds: [error.itemId] });
                    }
                });
            } else if (key === 'warnings') {
                value.forEach((warning) => {
                    const warningKey = slugify(warning.message, { lower: true, strict: true });
                    if (get(transFormedResponse, `messages.warnings.${warningKey}`)) {
                        const currentCount = get(transFormedResponse, `messages.warnings.${warningKey}.count`);
                        set(transFormedResponse, `messages.warnings.${warningKey}.count`, currentCount + 1);
                        const currentItemIds = get(transFormedResponse, `messages.errors.${warningKey}.itemIds`, []);
                        set(transFormedResponse, `messages.errors.${warningKey}.itemIds`, [...new Set([...currentItemIds, warning.itemId])]);
                    } else {
                        set(transFormedResponse, `messages.warnings.${warningKey}`, { count: 1, description: warning.message, itemIds: [warning.itemId] });
                    }
                });
            } else if (key === 'startDate') {
                transFormedResponse.startedOn = value;
            } else {
                transFormedResponse[key] = value;
            }
        });
        return transFormedResponse;
    };

    /**
     * Get logs for publish task
     * @param {string} jobId
     */
    static async logs(jobId, type) {
        try {
            const response = await Request.post('publishing/status', {
                jobToken: jobId,
                type
            });

            if (response.success === 1) {
                return response.data;
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    }
}

export default PublishV2;

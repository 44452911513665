import EditorData from 'components/editor-data/EditorData';

export function searchDatasetManagerPath() {
    const interfaceSetup = EditorData.get('interfaceSetup');
    const pages = interfaceSetup.pages;

    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];

        let hasDataSetManager = false;
        if (page.tabs) {
            for (let j = 0; j < page.tabs.length; j++) {
                const tab = page.tabs[j];
                if (tab.blocks) {
                    for (let k = 0; k < tab.blocks.length; k++) {
                        const block = tab.blocks[k];
                        if (block.type === 'dataSetManager') {
                            hasDataSetManager = true;
                        }
                    }
                }
            }
        }

        if (page.blocks) {
            for (let j = 0; j < page.blocks.length; j++) {
                const block = page.blocks[j];

                if (block.type === 'dataSetManager') {
                    hasDataSetManager = true;
                }
            }
        }

        if (hasDataSetManager) return page.path;
    }
}

import React from 'react';
import PublishDetailLogsTreeviewItem from './publish-detail-logs-treeview-item';
import '../styles/publish-detail-logs-treeview.scss';

const PublishDetailLogsTreeview = ({ data = [], jobId }) => {
    const constructListItem = (item) => {
        if (item.subTasks) {
            return (
                <PublishDetailLogsTreeviewItem jobId={jobId} key={item.id} item={item}>
                    {item.subTasks.map(constructListItem)}
                </PublishDetailLogsTreeviewItem>
            );
        } else {
            return <PublishDetailLogsTreeviewItem jobId={jobId} key={item.id} item={item} />;
        }
    };

    return (
        <React.Fragment>
            <div className="publish-detail-logs-treeview">
                <div></div>
                <div className="publish-detail-logs-treeview__head">status</div>
                <div className="publish-detail-logs-treeview__head">task id</div>
                <div className="publish-detail-logs-treeview__head">service</div>
                <div className="publish-detail-logs-treeview__head">type</div>
                <div></div>
                <div className="publish-detail-logs-treeview__head">end task</div>
                <div className="publish-detail-logs-treeview__head">start task</div>
                <div className="publish-detail-logs-treeview__head">duration</div>
                <div></div>
            </div>
            {data.map(constructListItem)}
        </React.Fragment>
    );
};

export default PublishDetailLogsTreeview;

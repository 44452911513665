import React, { useState } from 'react';
import classNames from 'classnames';
import Request from 'components/data/Request';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import Loader from 'components/ui-components/Loader';
import InlineAlert from 'components/ui-components/InlineAlert';
import Dialog from 'components/ui-components/Dialog';
import Status from 'components/ui-components/Status';
import { getDateFormatted } from 'components/ui-components/DateFormat';
import PublishDetailLogsLogview from './publish-detail-logs-logview';
import StatusHelpers from '../helpers/status';

import '../styles/publish-detail-logs-treeview-item.scss';

const PublishDetailLogsTreeviewItem = ({ item, children, jobId }) => {
    const [openSubTasks, setOpenSubTasks] = useState(false);
    const [openLog, setOpenLog] = useState(false);
    const [logData, setLogData] = useState(null);
    const [logError, setLogError] = useState(null);

    const handleOpenLog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (openLog) {
            setLogData(null);
            setLogError(null);
            setOpenLog(false);
        } else {
            setOpenLog(true);
            Request.post('publishing/status', {
                jobToken: jobId,
                type: 'logs',
                taskId: item.id
            })
                .then((response) => {
                    setLogData(response.data);
                })
                .catch((error) => {
                    setLogError(error);
                });
        }
    };

    const handleOpenSubTasks = () => {
        if (children) setOpenSubTasks(!openSubTasks);
    };
    return (
        <div className="publish-detail-logs-treeview-item">
            <div
                onClick={(e) => handleOpenSubTasks(e)}
                className={classNames('publish-detail-logs-treeview-item__header', {
                    'publish-detail-logs-treeview-item__header--subtasks': children,
                    'publish-detail-logs-treeview-item__header--open': openSubTasks
                })}>
                <div className="publish-detail-logs-treeview-item__header__toggle">
                    {children && <Icon>{openSubTasks ? 'expand_less' : 'expand_more'}</Icon>}
                </div>
                <div>
                    <Status status={StatusHelpers.getLogStatus(item.status)} small />
                </div>
                <div>{item.id}</div>
                <div>{item.service}</div>
                <div>{item.type}</div>
                <div></div>
                <div>{getDateFormatted(item.endTime, 'dateTimeExact')}</div>
                <div>{getDateFormatted(item.startTime, 'dateTimeExact')}</div>
                <div>{item.duration}</div>
                <div>
                    <Button variant="outlined" onClick={(e) => handleOpenLog(e)} size="small">
                        log
                    </Button>
                </div>
            </div>
            {openSubTasks && <div className="publish-detail-logs-treeview-item__content">{children && children}</div>}
            {openLog && (
                <Dialog title={`Log for task id ${item.id}`} onClose={handleOpenLog} open={true} fixedHeightBig maxWidth="xl">
                    <Loader isLoading={!logData && !logError}>
                        {logData && <PublishDetailLogsLogview data={logData} hideFilter />}
                        {logError && (
                            <div className="publish-detail-logs-treeview-item__log-error">
                                <InlineAlert>{logError.message ? logError.message : logError.errors}</InlineAlert>
                            </div>
                        )}
                    </Loader>
                </Dialog>
            )}
        </div>
    );
};

export default PublishDetailLogsTreeviewItem;

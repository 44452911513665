import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import EditorData from 'components/editor-data/EditorData';
import { getDateFormatted } from 'components/ui-components/DateFormat';
import PublishRequest from './request';
import { PublishProfile } from '../types/publishProfile.type';
import { PublishStatus } from '../types/publishResult.type';
import { LogsResponse, LogType } from '../types/publishLogs.type';

interface PublishProfileExtended extends PublishProfile {
    key: string;
}

interface PEV3Error {
    jobId: string;
    status: string;
    errors: string[];
    warnings: string[];
}

class PublishV3 {
    /**
     * Start a publish job on the PublishEngine V3
     */
    static async publish(publishProfile: PublishProfileExtended) {
        // PE V3
        try {
            console.log('PUBLISH VERSION', 3);
            const campaignId = Number(EditorData.getId());
            const response = await PublishRequest.post('jobs/start', { campaignId, publishProfile: publishProfile.key });

            //TODO: partial publishing
            return {
                ...response.data,
                status: 'processing',
                startedOn: getDateFormatted(Date.now(), 'dateTimeIso') // Client time, gets overwritten after first status call with server time.
            };
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                return PublishV3.error(undefined, 'connectionFail', [error.response?.data?.message]);
            }
            Sentry.captureException(error);
        }
    }

    /**
     * Cancel a specific job
     */
    static async cancel(jobId: string) {
        try {
            await PublishRequest.patch(`jobs/${jobId}/cancel`);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.log(`Cancelling failed: ${error.response?.data?.message}`);
            } else {
                Sentry.captureException(error);
            }
        }
    }

    /**
     * Cancel a specific job
     */
    static async poll(jobId: string) {
        try {
            const response = await PublishRequest.get(`jobs/${jobId}/status`);

            const status = (() => {
                if (response.data?.status === 2 && response.data?.failedTasks > 0) return 'failed';
                return PublishV3.getStatus(response.data?.status);
            })();
            return {
                ...response.data,
                jobId,
                status
            };
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                return PublishV3.error(jobId, 'connectionFail', [error.response?.data?.message]);
            }
            Sentry.captureException(error);
        }
    }

    /**
     * Gets logs for publish job
     */
    static async logs(jobId: string, type?: LogType, custom?: object): Promise<LogsResponse | undefined> {
        try {
            const response = await PublishRequest.get<LogsResponse>(`jobs/${jobId}/logs`, {
                params: {
                    tree: type === 'tree',
                    ...custom
                }
            });

            return response.data;
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    }

    /**
     * Return a formatted error if somethig goes wrong.
     */
    static error(jobId?: string, status = 'failed', errors: string[] = [], warnings: string[] = []): PEV3Error {
        return {
            jobId: jobId || `fake-${uuidv4()}`,
            status,
            errors,
            warnings
        };
    }

    /**
     * We receive a status number fom the api, we want to translate that to the status codes the frontend uses.
     */
    static getStatus = (apiStatus: number): PublishStatus => {
        switch (apiStatus) {
            case 0:
                return 'processing';
            case 1:
                return 'processing';
            case 2:
                return 'done';
            case 3:
                return 'cancelled';
            case 4:
                return 'scheduled';
            default:
                return 'failed';
        }
    };
}

export default PublishV3;

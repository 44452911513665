import Validator from 'components/data/Validator';
import { getDateFormatted } from 'components/ui-components/DateFormat';

class Status {
    /**
     * Determine the status of a publish profile
     * @param {object*} publishProfile
     * @param {object} result
     * @returns string indicating the stautus
     */
    static current(publishProfile, result) {
        const validateResults = this.validate(publishProfile);
        if (validateResults.length > 0) {
            return 'invalid';
        } else {
            return result ? result.status : 'new';
        }
    }

    /**
     * Get the ststus text to display in the subTitle of the list
     * @param {string} status
     * @param {string} date
     * @returns
     */
    static getStatusText(status, date) {
        switch (status) {
            case 'done':
                return `Last published: ${date}`;
            case 'failed':
                return `Publish failed: ${date}`;
            case 'cancelled':
                return `Publish cancelled: ${date}`;
            case 'starting':
                return 'Submitting publish task list';
            case 'processing':
                return 'Publishing now';
            case 'invalid':
                return 'Campaign input not complete';
            case 'scheduled':
                return `Waiting to be published ${date}`;
            default:
                return 'Ready to publish';
        }
    }

    /**
     * Get the date string to display in the subTitle of the list
     * @param {*} result
     * @returns
     */
    static getStatusDate(result) {
        if (result && result.startDate) {
            return getDateFormatted(result.startDate, 'fromNow');
        } else if (result && result.date) {
            // Lecacy start date
            return getDateFormatted(result.date, 'fromNow');
        } else {
            return 'unknown';
        }
    }

    /**
     * Validate a publish profile and return the result
     * @param {object*} publishProfile
     * @returns Array of validation issues
     */
    static validate(publishProfile) {
        if (publishProfile.validators) {
            return Validator.validate(publishProfile.validators);
        }
        return [];
    }

    /**
     * Group messages together
     * In case errors are the same, group them
     * @param {*} messages
     * @returns
     */
    static group(messages) {
        const grouped = [];
        for (let i = 0; i < messages.length; i++) {
            const index = grouped.findIndex((x) => x.message === messages[i].message);
            if (index > -1) {
                const lastNumber = grouped[index].num;
                grouped[index] = { message: messages[i].message, num: lastNumber + 1 };
            } else {
                grouped.push({ message: messages[i].message, num: 1 });
            }
        }
        return grouped;
    }

    /**
     * Translate the status as received from log call, to a status the Status component can use.
     * @param {string} value as returned from status log call
     * @returns
     */
    static getLogStatus = (value) => {
        switch (value) {
            case '0':
                return 'pending';
            case '1':
                return 'queued';
            case '2':
                return 'processing';
            case '3':
                return 'done';
            case '-1':
                return 'failed';
            default:
                return '';
        }
    };

    /**
     * copyToClipboard
     * Copy a string to the users clipboard.
     * @param {string} text String to be copied
     */
    static copyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }
}

export default Status;

import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import GenericFilter from 'components/ui-base/GenericFilter';
import FilterHelpers from 'components/data/FilterHelpers';
import Translation from 'components/data/Translation';
import PublishDetailLogsLogviewItem from './publish-detail-logs-logview-item';
import { LogSeverity } from '../types/publishLogs.type';
import '../styles/publish-detail-logs-logview.scss';

/**
 * Get a human reable text for a log level.
 * @param {number} level
 * @returns
 */
const getSeverityText = (level) => {
    const severity = isNaN(level) ? level : parseInt(level);

    if (severity === 2 || severity === LogSeverity.NOTICE) {
        return Translation.get('publish.detailLogsDialog.logView.severity.notice', 'editor');
    } else if (severity === 3 || severity === LogSeverity.INFO) {
        return Translation.get('publish.detailLogsDialog.logView.severity.info', 'editor');
    } else if (severity === LogSeverity.DEBUG) {
        return Translation.get('publish.detailLogsDialog.logView.severity.debug', 'editor');
    } else if (severity === 5 || severity === LogSeverity.ERROR) {
        return Translation.get('publish.detailLogsDialog.logView.severity.error', 'editor');
    } else if (severity === 6 || severity === LogSeverity.CRITICAL) {
        return Translation.get('publish.detailLogsDialog.logView.severity.critical', 'editor');
    } else if (severity === 7 || severity === LogSeverity.WARNING) {
        return Translation.get('publish.detailLogsDialog.logView.severity.warning', 'editor');
    }

    return `${Translation.get('publish.detailLogsDialog.logView.severity.unknown', 'editor')}: ${level}`;
};

/**
 * Get the relevnt options for the filters.
 * @param {array} data
 * @returns
 */
const getFilterSetup = (data = []) => {
    const severityOptions = [];
    const severityCheck = [];
    const locationOptions = [];
    const locationCheck = [];
    const applicationOptions = [];
    const applicationCheck = [];

    data.forEach((log) => {
        if (!severityCheck.includes(log.severity)) {
            severityOptions.push({ key: log.severity, value: getSeverityText(log.severity) });
            severityCheck.push(log.severity);
        }
        if (log.location && !locationCheck.includes(log.location)) {
            locationOptions.push({ key: log.location, value: log.location });
            locationCheck.push(log.location);
        }
        if (log.application && !applicationCheck.includes(log.application)) {
            applicationOptions.push({ key: log.application, value: log.application });
            applicationCheck.push(log.application);
        }
    });

    const filterSetup = [
        {
            label: 'Type',
            name: Translation.get('publish.detailLogsDialog.logView.filter.severity', 'editor'),
            type: 'selectMultiple',
            options: severityOptions
        }
    ];

    if (locationOptions.length) {
        filterSetup.push({
            label: 'Location',
            name: Translation.get('publish.detailLogsDialog.logView.filter.location', 'editor'),
            type: 'selectMultiple',
            options: locationOptions
        });
    }
    if (applicationOptions.length) {
        filterSetup.push({
            label: 'Application',
            name: Translation.get('publish.detailLogsDialog.logView.filter.applicaiton', 'editor'),
            type: 'selectMultiple',
            options: applicationOptions
        });
    }

    return filterSetup;
};

const PublishDetailLogsLogview = ({ data = [], hideFilter, taskIdClickable }) => {
    const [filterSetup, setFilterSetup] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize] = useState(50);
    const [forceSearchTerm, setForceSearchTerm] = useState(false);

    useEffect(() => {
        setFilterSetup(getFilterSetup(data));
    }, [data]);

    useEffect(() => {
        const list = FilterHelpers.handleFilter(data, filters, ['taskId', 'message']);
        setFilteredData(list);
        setForceSearchTerm(false);
    }, [data, filters]);

    /**
     * Set filtering to show all logs of a certain task id.
     * @param {string} taskId
     */
    const showTaskId = (taskId) => {
        const filters = {
            searchTerm: taskId
        };
        setFilters(filters);
        setForceSearchTerm(taskId);
    };

    /**
     * Set the filters
     * @param {object} filters
     */
    const onFilter = (filters) => {
        setFilters(filters);
        setPage(1);
    };

    /**
     * Set the page of the pagination.
     * @param {*} _
     * @param {number} page
     */
    const onSetPage = (_, page) => {
        setPage(page);
    };

    return (
        <React.Fragment>
            {!hideFilter && (
                <GenericFilter
                    showHorizontal={2}
                    searchField
                    searchTerm={filters.searchTerm}
                    forceSearchTerm={forceSearchTerm}
                    filters={filters}
                    filterSetup={filterSetup}
                    onMutation={onFilter}
                    searchPlaceholder="Search on task id or message"
                    className="publish-detail-logs-logview__filter"
                />
            )}
            <div className="publish-detail-logs-logview">
                <div></div>
                <div className="publish-detail-logs-logview__head">Time</div>
                <div className="publish-detail-logs-logview__head">Type</div>
                <div className="publish-detail-logs-logview__head">Task ID</div>
                <div className="publish-detail-logs-logview__head">Item ID</div>
                <div className="publish-detail-logs-logview__head">Location</div>
                <div className="publish-detail-logs-logview__head">Message</div>
                <div></div>
            </div>
            {[...filteredData.slice(pageSize * page - pageSize, pageSize * page)].map((item) => (
                <PublishDetailLogsLogviewItem key={item.id} item={item} onShowTaskId={showTaskId} taskIdClickable={taskIdClickable} />
            ))}
            {Math.ceil(filteredData.length / pageSize) > 1 && (
                <Pagination
                    count={Math.ceil(filteredData.length / pageSize)}
                    page={page}
                    onChange={onSetPage}
                    classes={{
                        root: 'publish-detail-logs-logview__pagination'
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default PublishDetailLogsLogview;

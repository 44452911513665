import axios from 'axios';
import User from 'components/data/User';

/**
 * Set initial request data
 */
const PublishRequest = axios.create({
    baseURL: process.env.PUBLISH_ENGINE_V3,
    timeout: process.env.APP_API_TIMOUT,
    withCredentials: true
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
PublishRequest.interceptors.request.use(
    (config) => {
        try {
            const JWTtoken = User.get('apiToken');

            config.headers.Authorization = `Bearer ${JWTtoken}`;
            return config;
        } catch (e) {
            console.error(e);
        }
    },

    (error) => {
        return Promise.reject(error);
    }
);

export { PublishRequest };
export default PublishRequest;
